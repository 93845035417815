import React, { useEffect, useState } from 'react'
import { message, Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import ClubEditor from './ClubEditor'
import { useStatus, useStatusMsg } from '../../reducers'
import { createClub } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'
import { newSid } from '../../util/id'
import get from 'lodash.get'

const NewClub = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { osid } = useParams()
  const [club, setClub] = useState({ sid: newSid() })
  const status = {
    create: useStatus(acts.CREATE_CLUB),
  }

  useEffect(() => {
    if (!get(club, 'sid')) {
      setClub(c => ({ ...c, sid: newSid() }))
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_CLUB))
    }
  }, [])

  useStatusMsg(status.create, {
    pending: 'Creating...',
    error: 'Failed to create club',
    success: res => {
      return (
        <>
          Club created. <a href={paths.org.CLUB(osid, res.club.sid)}>View</a>
        </>
      )
    },
  })

  const missingFields = () => {
    const missing = ['name', 'description', 'cover', 'sid'].filter(k => {
      if (!club[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const submitClub = createAnother => () => {
    if (missingFields()) {
      return
    }
    // convert club.sponsors to an array of ids
    const sponsors = club.sponsors
      ? club.sponsors.map(spn => {
          // spn can be either a club-sponsor model or a sponsor model
          return spn.sponsor ? spn.sponsor.id : spn.id
        })
      : []

    // convert club.venues to an array of ids
    const venues = club.venues ? club.venues.map(venue => venue.id) : []

    const benefit = typeof club.benefit === 'string' ? club.benefit : get(club.benefit, 'id')

    dispatch(createClub(osid, { ...club, sponsors, benefit, venues }))
      .then(club => {
        if (createAnother) {
          setClub(null)
        } else {
          history.push(paths.org.CLUB(osid, club.sid))
        }
      })
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Create a club"
        actions={
          <>
            <Button
              style={{ marginRight: '.5em' }}
              onClick={submitClub(true)}
              size="small"
              disabled={status.create.pending ? true : false}
            >
              Submit and create another
            </Button>
            <Cta disabled={status.create.pending ? true : false} onClick={submitClub(false)}>
              {status.create.pending ? 'Submitting club...' : 'Submit club'}
            </Cta>
          </>
        }
      />
      <FocusContainer style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <ClubEditor club={club} onChange={c => setClub(c)} />
      </FocusContainer>
    </>
  )
}

export default NewClub
